import React from 'react'
import { Button, Col, Card, CardBody , CardImg} from 'reactstrap';
import { config } from '..';

export default function HomePublic() {
    return (
    <>
    <Col sm={{ size: 4, offset: 4 }}>
    <Card style={{border: 'none'}}>
      <CardImg src={config.siteImg} alt={config.altImg} width="50%"/>
      <CardBody>
          <h2 className="text-center" style={{color: "navy"}}>
            <b>{config.siteLabel}</b>
          </h2>
        <div className="text-center">
          <Button color="primary" size="lg" href={config.auth.login}>
            <i className="fa ,-sign-in-alt"></i> Login </Button>
          </div>
      </CardBody>
    </Card>
    </Col>
    </>
    );
  }
