import axios from 'axios'
import config from '../../config'
const {app} = config;
const {api} = app.filter( (item) => (item.nameSpace === "myProfile"))[0]

export default function setProfile(token, id = null) {
    console.log(api.setProfile, token, id)
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.post(api.setProfile + (id ? "/" + id : ""), {}, axiosConfig)
}