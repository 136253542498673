import axios from 'axios'
import config from '../../config'
const {app} = config;
const {api} = app.filter( (item) => (item.nameSpace === "my"))[0]

export function getMyGoals(token) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getMyGoals, axiosConfig)
}

export default function getCurrent(token) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getCurrent, axiosConfig)
}

export function setProfile(token, data) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.post(api.getMyGoals, data, axiosConfig)
}