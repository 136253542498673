import axios from 'axios'
import config from '../../../config'

const {appAdmin} = config;
const {api} = appAdmin.filter( (item) => (item.nameSpace === "user"))[0]

export default function getUser(token, email) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getUser + "?email=" + email, axiosConfig)
}

export function getUserPlacementsTest(token, email) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getPlacementsTest + "?email=" + email, axiosConfig)
}

export function getUserGoals(token, email) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getUserGoals + "?email=" + email, axiosConfig)
}
