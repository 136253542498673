import React, {useState} from 'react'
import {Col, Row, Alert, Input, Button, } from 'reactstrap'
import {setConfig} from './apiActions'

const defaultState = {currentTargetLanguage: "0" , minTotHours: 0}

const Configuration = ({type = "", id, token, languages=[]}) => {
    const [state, setState] = useState(defaultState)

    const sendOption = () => {
        const settatoLanguage = state.currentTargetLanguage !== "0"
        const cfu = type === "accreditationTrainingCredits"
        const settatoCfu = cfu ? state.minTotHours !== 0  : true

        if(settatoLanguage && settatoCfu){
            console.log(state)
            
            setConfig(token, id, state)
            .then( (res)=>{
                console.log(res)
                //alert("Inviato")
                window.location.reload()
            })
            .catch( (err) => {
                alert(err.message.toString())
            })
        }
    }

    function setOption (field, value){
        
        const t = state
        t[field] = value
        setState(t)
        
    } 

    return <Row className="justify-content-md-center">
        <Col md={12} xs={12}>
            <Alert color="null">
                <i className="fa fa-lock-open"></i> <br />
                Lingua <Input type="select" name="config" 
                    onChange={(e)=>{setOption( 'currentTargetLanguage',e.currentTarget.value)}}>
                        <option value={0}>Scegli la lingua</option>
                    {languages.map( item => {
                        return <option key={item.id} value={item.id}>{item.description}</option>
                    })}
                </Input>
                {type === "accreditationTrainingCredits" ?
           <>CFU: <Input type="select" onChange={(e)=>{setOption('minTotHours', parseInt(e.currentTarget.value) )}} >
                    <option value={0} >Scegli il numero di CFU</option>
                    {[ 25,50,75,100,125,150 ].map( (val, key) => {
                       return <option key={val} value={val}> {key+1} cfu</option>
                    })}
                </Input>
            </> : null }
                
                <Button color="primary" onClick={sendOption} size="lg" style={{margin: 20}}>Invia</Button>
            </Alert>
        </Col>
    </Row>
}
export default Configuration