import React from 'react'
import moment from 'moment'
import {Alert} from 'reactstrap'

const defaultFormat = 'DD/MM/YYYY [alle ore] H:mm'

const Report = ({userGoal}) => {
    const {minTotHours} = userGoal
    
    const report = userGoal.report || null

    const avviso = "(il primo report potrebbe essere disponibile dopo 15-20 giorni dall'avvio dell'obiettivo, non prima di Gennaio 2021)"

    if(!report){
       return <Alert color="success">
            {minTotHours ? <p className="text-center">Ore Totali: <b>--</b>/<b>{minTotHours} ore</b></p> : null }
            <p className="text-left">Data ultimo Monitoraggio: <b>Spiacente 
                non è disponibile al momento un report<br/></b>
                <em>{avviso}</em>
                </p>
            </Alert>
    }   
    const {totHours, currentTargetLanguage, lastMonitoring} = report
    const {foundation, fluencybuilder, description} = currentTargetLanguage
    const tot = totHours || "--"
    const dataUltimo = lastMonitoring ? moment(lastMonitoring).format(defaultFormat) : avviso
   
   return <Alert color="success">
            <p className="text-center">Ore Totali: <b>{tot}</b>/<b>{minTotHours} ore</b></p>
            <p className="text-center">Data ultimo Monitoraggio: {dataUltimo}</p>
            <p> 
                Target: <b>{description}</b> <br />
                {fluencybuilder ? <i>[Fluency Builder]</i> : null} {` `}
                {foundation? <i>[Foundation]</i> : null} 
            </p>
            
            </Alert>
}
export default Report
