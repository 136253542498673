import React, {useState, useEffect} from 'react'
import config from '../../../config'
import Editor from './edit'
import StoreContext from '../../core'
import getGoals, { getLanguages, setGoal, updateGoal, getDepartments } from './apiActions';
import { initialValues } from './config';
import {Button, Modal, ModalBody, ModalHeader, Spinner, Row, Col} from 'reactstrap'
import GoalsList from './goalsList'

// lista di goals esistenti
// creazione di un nuovo goal
const {appAdmin} = config;
const {pageTitle} = appAdmin.filter( (item) => (item.nameSpace === "Goals"))[0]



export default function Goals () {
    const {token, isAdmin} = React.useContext(StoreContext) 
    const [languages, setLanguages] = useState([])
    const [departments, setDepartments] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [goal, editGoal] = useState(initialValues)
    const [edit, setEdit] = useState(false)
    const [elenco, setElenco] = useState([])

    const resetElenco = React.useCallback( () => {
            getGoals(token)
            .then( res => {
                setElenco(res.data)
            })
    }, [token])

    const toggleOpen = () => setIsOpen(!isOpen)

    const openModal = () => {
        if(!isOpen){
            setIsOpen(true)
            getDepartments(token)
            .then(res => {
                setDepartments(res.data)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        getLanguages(token)
        .then(res => {
            setLanguages(res.data)
            resetElenco() 
        })
        
    }, [token, resetElenco]);

    function submit (values) {
        if(edit)
            return (updateGoal(token,values))

        return setGoal(token, values)
    }
   
    const NewGoalButton =  () => <Col md={2} style={{textAlign: 'right'}} className="offset-md-10">
            <Button color="primary" 
                onClick={
                    ()=>{
                        setEdit(false); 
                        editGoal(initialValues); 
                        openModal()
                    }
            }>
                <i className="fa fa-plus-circle"></i>&nbsp;Nuovo
            </Button>
        </Col> 

    return <>
    <Row>
    <Col md={12}>
        <h2>{pageTitle}</h2>
    </Col>
    {!!isAdmin ? <NewGoalButton /> : null}
    <Col md={12} style={{paddingTop: 18}}>
       {elenco.length ? <GoalsList 
        languages={languages} 
        elenco={elenco}
        isAdmin={isAdmin}
        editGoal={ (item) => {setEdit(true); editGoal(item); openModal() }} /> : null}
    </Col>
    </Row>
        <Modal isOpen={isOpen} size="xl">
            <ModalHeader toggle={ () => { 
                toggleOpen() 
            }}>
                Impostazioni del Goal
            </ModalHeader>
            <ModalBody>
                {loading ? <Spinner />:
                <>
                {languages.length && departments.length ? 
                    <Editor languages={languages} 
                        departments={departments} 
                        submitFunction={submit} 
                        goal={goal} 
                        resetElenco={resetElenco}
                        isAdmin={!!isAdmin}
                    /> 
                    : null 
                    }
                </>
                }
                
            </ModalBody>
        </Modal>
    </>
}