import React from 'react'
import {getConfig} from './apiActions'
//import {Button} from 'reactstrap'
//import MessageContext from '../core'
import TemplateComponent from 'react-mustache-template-component'
import ReactDOMServer from 'react-dom/server';

function removeTags(str) {
    if ((str===null) || (str===''))
    return false;
    else
    str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
 }

export default function RosettaStoneLink ({token, userGoal}) {
    const [link, setLink] = React.useState("")

    React.useEffect( () => {
        getConfig(token)
        .then( ({data}) => {
            setLink (data.ssoURLRosettaStone)
            //console.log(data)
        })
        .catch( (err) => {
            console.log(err)
        })
    }, [token])

    //Il renderrToString  consente di prerenderizzare il componente, vist oche il templateComponent altrmenti, renderizzerebbe a posteriori.
    const redirecUrlRosetta = removeTags(ReactDOMServer.renderToString(<TemplateComponent template={link} data={{userGoal}} />)).replaceAll("&amp;","&")
    
    const message = "Stai per essere indirizzato alla piattaforma Rosetta Stone, potrebbero essere richieste nuovamente le credenziali d'accesso. Sei invitato a continuare."

return <div className="alert alert-default" style={{backgroundColor: "#f9f9f9", border: '1px solid #eee'}}>
        <a href={"."} onClick={(e)=>{
            e.preventDefault(); 
            
            if(window.confirm(message)){
                window.location.href = redirecUrlRosetta
            }
            
            }} alt="Link a RosettaStone"  >
        <img style={{margin: "4px", height: "64px"}} src="/assets/rosetta-stone-logo.png" alt="logo rosettaStone"/>
        <b style={{fontSize: "1.5em" ,textShadow:  "1px 1px 2px #000"}}> Avvia Rosetta Stone</b>
        </a>
    </div> 
}