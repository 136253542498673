import React, {useState} from 'react'
import Table from '../table'
import columns, {hiddenColumns} from './columns'
import StoreContext from '../../core'
import getPlacements from './apiActions'
import config from '../../../config'
import {useParams} from 'react-router-dom'
import FilterModal from '../filters'

const {appAdmin} = config;
const { pageTitle} = appAdmin.filter( (item) => (item.nameSpace === "placements"))[0]


export default function ReportPlacements(){

  const {token} = React.useContext(StoreContext) 
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const {email} = useParams();
  const [filter, setFilter] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const toggle = () => setIsOpen(!isOpen)

  const preFilter = email !== ":email" ? [{ id: 'email', value: email}] : null

  function filterData (ids){
    const data = {}
    data.type='placements'
    data.values = ids
    setFilter(data)
    setIsOpen(true)
 
  }

  // selezione dell'elenco degli id caricati tramite API.
  function setTableFilter(data){
    setSelectedRows(data)
  }

  React.useEffect( 
    () => 
    {
      getPlacements(token)
      .then( res => {
        const {data} = res
        
          data.forEach( item => {
          try{        
                  const {firstName = "", lastName = "", matricola = "", email = ""} = item
                  item.fullName = `${firstName} ${lastName} [${matricola}] ${email}`
                  return item
                }
          catch( err) {
          console.log(err, item)
        }}
            )
            

        setData(data)
      })
      .then(()=>{
        setLoading(false)
      })
      .catch( err => {
        console.log('ERRORE.' + err)
        setLoading(false)
      })

    }, [token])

 return <>
    <h2 style={{padding: 24}}>{pageTitle}</h2>
    <Table 
        data={data} 
        columns={columns} 
        loading={loading} 
        hiddenColumns={hiddenColumns} 
        selection={false} // per attivare i filtri
        filterData={(data)=>filterData(data)} 
        preFilter={preFilter} 
        selectedRows={selectedRows} 
        setTableFilter={(data)=>setTableFilter(data)}
      />
   <FilterModal item={filter} isOpen={isOpen} toggle={toggle}/>
   
 </>
}