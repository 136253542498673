import React from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useRowSelect } from 'react-table'
import XLSX from 'xlsx'
import {Input, Row,Col, Button, Spinner, Alert, 
   Pagination, PaginationItem, PaginationLink,
  } from 'reactstrap';
import {FiltersRow} from "../filters"

const PaginationStyle = { width: '100px', height: '30px' }
// Chekboxes required this function
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Input addon type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows , setFilter},
}) {
  const count = preFilteredRows.length

  
  return (
    <Input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={count ?`Cerca` : "0 records"}

    />
  )
}

function esportaXls (data) {
  let t = []
  data.map( item => ( t.push(item.original)))
  const wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(t);
  XLSX.utils.book_append_sheet(wb, ws, "foglio1")
  XLSX.writeFile(wb, "esportazione.xlsx");
}


function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (

      <Input
        value={value || ""}
        color="outline"
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Cerca..."
      />

  )
}

export default function Table ({data, columns, loading, hiddenColumns, preFilter, selection, selectedRows, setTableFilter, filterData}) {

  
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
const initialState = {hiddenColumns: hiddenColumns, pageIndex: 0, pageSize: 10, selectedRowIds : [] }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

    //console.log(selectedFlatRows)
    React.useEffect ( () => {
    
      // this happens oonly on reload data when call globalFilter.
    // console.log(preFilter)
   
    if(preFilter){
      initialState.filters = preFilter
    }

    if(selectedRows){
        const temp = {}
        const t = data.findIndex( item => {
          if (item.id.indexOf(selectedRows) > -1)
            return true
          return false
        })
        console.log(t)
    initialState.selectedRowIds = temp

    }


    }, [selectedRows, preFilter, initialState, data])

  
  
   const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    page,
    state: { pageIndex, pageSize, selectedRowIds }
   // preGlobalFilteredRows,
   // visibleColumns,
   } = useTable({ columns, data,  defaultColumn, filterTypes, 
  initialState: initialState,
  },
   useFilters, 
   useGlobalFilter,
   usePagination,
   useRowSelect,
   hooks => {
     hooks.visibleColumns.push(columns => [
      // Let's make a column for selection
      selection ? {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <>
           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <>
             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> 
          </>
        ),
      } : {},
      ...columns,
    ])  
  },
   )



  const Paginazione = () => <Pagination aria-label="Paginazione" size="lg">
    <PaginationItem>
      <PaginationLink first onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink previous onClick={() => previousPage()} disabled={!canPreviousPage} />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink next onClick={() => nextPage()} disabled={!canNextPage} />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink style={{color: 'black'}}>
      Pagina&nbsp;<b>{pageIndex + 1}&nbsp;di&nbsp;{pageOptions.length}</b>
      </PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink>
        <Input
                className="form-control"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                }}
               style={PaginationStyle}
            />
      </PaginationLink>
      
    </PaginationItem>
    <PaginationItem>
        <PaginationLink>
              <Input type="select"
              style={PaginationStyle}
              value={pageSize}
              bsSize="sm"
              onChange={e => {
                  setPageSize(Number(e.target.value))
              }}
          >
              {[ 10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                      {pageSize}
                  </option>
              ))}
          </Input>
        </PaginationLink>
      </PaginationItem>
      <PaginationItem
      >
        <PaginationLink>
        <b>{rows.length}</b>&nbsp;records.
        </PaginationLink>
      </PaginationItem>
    </Pagination>


  if(loading)
        return <Row>
        <Col className="text-center"><Spinner color="info" />
       </Col>
      </Row>

  if(!loading && !data.length){
    return <Row>
      <Col>
        <Alert color="info">
          Non sono presenti dati.
        </Alert>
      </Col>
    </Row>
  }

   return (
     <>
     <Row className={"justify-content-center"}>
       <Col>
        <Paginazione />
        
       </Col>
      </Row>
     <Row style={{paddingBottom: 10}}>
      <Col md="10"> <GlobalFilter
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
      </Col>
      <Col className="text-right">
        <Button onClick={()=>esportaXls(rows)}>Esporta XLS</Button>
      </Col>
     </Row>
    
    {selection ?  <Row style={{paddingBottom: 10}}>
       <Col className="text-left">
         <FiltersRow 
          selectedFlatRows={selectedFlatRows} 
          selectedRowIds={selectedRowIds} 
          setTableFilter={(ids)=>setTableFilter(ids)} 
          allParams={state.filters}
          filterData={filterData} 
          saveFilterEnabled={state.filters.length || (selection && Object.keys(selectedRowIds).length)} />
       </Col>
     </Row> : null }

     <table {...getTableProps()} className="table table-bordered table-striped">
       <thead className="thead-dark">
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th scope="col" {...column.getHeaderProps()}>
                 {column.render('Header')}
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {page.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (
                   <td {...cell.getCellProps()} >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
       <tfoot className="thead-dark">
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th scope="col" {...column.getHeaderProps()}>
                 {column.render('Header')}
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
               </th>
             ))}
           </tr>
         ))}
       </tfoot>
     </table>
     <Paginazione />

     </>
   )
}