import axios from 'axios'
import config from '../../../config'

const {appAdmin} = config;
const {api} = appAdmin.filter( (item) => (item.nameSpace === "Goals"))[0]

const departmentsList = [
    { 
        "_id" : "5fad7c9a615413052504a21e", 
        "cod_dip" : "D01", 
        "id_dip" : "100349", 
        "des_dip" : "Dipartimento di Giurisprudenza", 
        "sigla" : "GIURISPRUDENZA"
    },
    { 
        "_id" : "5fad7cff615413052504a221", 
        "cod_dip" : "D03", 
        "des_dip" : "Dipartimento di Medicina Clinica e Sperimentale", 
        "id_dip" : "100351", 
        "sigla" : "DIMED"
    },
    { 
        "_id" : "5fad7d03615413052504a222", 
        "cod_dip" : "D04", 
        "des_dip" : "Dipartimento di Civiltà antiche e moderne", 
        "id_dip" : "100347", 
        "sigla" : "DICAM"
    },
    { 
        "_id" : "5fad7d05615413052504a223", 
        "cod_dip" : "D05", 
        "des_dip" : "Dipartimento di Scienze biomediche, odontoiatriche e delle immagini morfologiche e funzionali", 
        "id_dip" : "100353", 
        "sigla" : "BIOMORF"
    },
    { 
        "_id" : "5fad7d07615413052504a224", 
        "cod_dip" : "D06", 
        "des_dip" : "Dipartimento di Patologia Umana dell'adulto e dell'età evolutiva \"Gaetano Barresi\"", 
        "id_dip" : "100352", 
        "sigla" : "PATOLOGIA"
    },
    { 
        "_id" : "5fad7d0a615413052504a225", 
        "cod_dip" : "D07", 
        "des_dip" : "Dipartimento di Scienze chimiche, biologiche, farmaceutiche e ambientali", 
        "id_dip" : "100354", 
        "sigla" : "CHIBIOFARAM"
    },
    { 
        "_id" : "5fad7d48615413052504a230", 
        "cod_dip" : "D09", 
        "des_dip" : "Dipartimento di Scienze matematiche e informatiche, scienze fisiche e scienze della terra", 
        "id_dip" : "100356", 
        "sigla" : "MIFT"
    },
    { 
        "_id" : "5fad7d55615413052504a233", 
        "cod_dip" : "D10", 
        "des_dip" : "Dipartimento di Ingegneria", 
        "id_dip" : "100350", 
        "sigla" : "INGEGNERIA"
    },
    { 
        "_id" : "5fad7d60615413052504a236", 
        "cod_dip" : "D11", 
        "des_dip" : "Dipartimento di Scienze politiche e giuridiche", 
        "id_dip" : "100358", 
        "sigla" : "SCIPOG"
    },
    { 
        "_id" : "5fad7d61615413052504a237", 
        "cod_dip" : "D12", 
        "des_dip" : "Dipartimento di Economia", 
        "id_dip" : "100348", 
        "sigla" : "ECONOMIA"
    },
    { 
        "_id" : "5fad7d64615413052504a238", 
        "cod_dip" : "D20", 
        "des_dip" : "Dipartimento di Scienze Veterinarie", 
        "id_dip" : "100359", 
        "sigla" : "VETERINARIA"
    },
    { 
        "_id" : "5fad7d66615413052504a239", 
        "cod_dip" : "D24", 
        "des_dip" : "Dipartimento di Scienze cognitive, psicologiche, pedagogiche e degli studi culturali", 
        "id_dip" : "100355", 
        "sigla" : "COSPECS"
    }
]
 
const types = [
    {id: "lessonsSupport", description: "Idoneità linguistica"},
    {id: "custom", description: "Personalizzato (Nessun dettaglio da specificare)"},
    {id: "custom", description: "Personalizzato (Nessun dettaglio da specificare)"},
    {id: "accreditationTrainingFixedCredit", description: "Training Fixed Credit"},
    {id: "freeChoiceTeacherNotMonitoring", description: "Libera a scelta del docente (non monitorata)"},
    {id: "freeChoiceStudentNotMonitoring", description: "Libera a scelta dello studente (non monitorata)"},
    {id: "accreditationTrainingCredits", description: "Cfu a scelta dell'utente (Si imposta il peso a -1)"},
]

export default function getGoals(token) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
  
    return axios.get(api.getGoals , axiosConfig)
}

export function getTypes(token) {
   const response = {data: types}
   return Promise.resolve(response);
   /*
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.get(api.getLanguages, axiosConfig)
    */
}

export function getLanguages(token) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.get(api.getLanguages, axiosConfig)
}

export function getDepartments(token) {
  /*
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getDeparmtents, axiosConfig)
  */ 
   const response = {data: departmentsList}
   return Promise.resolve(response);
}

export function setGoal(token, data) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.post(api.setGoal, data, axiosConfig)
}


export function updateGoal(token, data) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.put(api.setGoal + "/" + data.id, data, axiosConfig)
}