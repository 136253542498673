import React, { useState, useContext} from 'react'
import {Container, Row, Col, Card, CardTitle, CardBody,  Alert, Button, Spinner} from 'reactstrap'
import {useParams, Link, Redirect} from 'react-router-dom'
import getGoalById, {setStatus} from './apiActions'
import Status from './status'
import Report from './report'
import UserData from './userData'
import GoalInfo from './info'
import Configuration from './configuration'
import RosettaStoneLink from './rosettaStoneLink'
import moment from 'moment'
import StoreContext from '../core'

export default function UserGoal ({token}) {
    const [userGoal, setUserGoal] = useState({})
    const [loading, setLoading] = useState(true)
    const [goMyHome, setGoMyHome] = useState(false)
    const { id } = useParams();
    const {role} = useContext(StoreContext)
    
    const isReferente = role.includes('RefDepStud')

    React.useEffect( ()=>{

        
        getGoalById({token,id})
        .then( ({data}) => {
          setUserGoal(data)
        }) 
        .catch(err => {
          console.log(err)
        }).then( () =>{
          setLoading(false)
        })
      
     }, [token, id])
    
     if(loading)
      return <Container>
          <Row  className="justify-content-md-center">
            <Col className="text-center">
            <Spinner color="primary" size="lg"/>
            </Col>
          </Row>  
        </Container>
          
     if(!userGoal.hasOwnProperty('goal'))
      {
        return <Alert color="warning">
          <p>Goal non trovato. </p>
          <Link to="/"><Button color="primary">Torna alla home.</Button></Link>
        </Alert>
      }

    const {  userData, createdAt, verifiedAt = null, closedAt = null, canceledAt = null,  goal, status, current, cefrLevel, currentTargetLanguage } = userGoal 
    const { monitoring, requiredConfiguration, type, languages} = goal
    
   // const totHours = report && report.totHours ? report.totHours : 0

    const firstDate = moment(createdAt)
    const secondDate = moment()
    const diffDays = Math.abs(secondDate.diff(firstDate, 'days'))
    const timePassed = diffDays > 15

    //console.log(firstDate, secondDate, diffDays, timePassed)
    const isInit = status === "init" && current && requiredConfiguration 
    const enableReport = monitoring === true && status !== 'init'
    const isRun = status === 'run'
    const isClosed = status === "closed"
    //const goalAcquired = totHours ? minTotHours - totHours >= 0 : false
    
    const isCanceled = status==='canceled'
    const canClose =  timePassed && !(['closed','init', 'canceled', 'verified'].indexOf(status) > -1)
    const canInterrupt = canClose && monitoring && !isCanceled
    //const canVerify = (status !== 'verified') && (monitoring && goalAcquired && timePassed) && !isCanceled
    const canVerify = isReferente && ( isRun || isClosed)
    //const customMessage = true
    

    function setGoalStatus(goalStatus){

      let userResponse = "" 
      
      if(canInterrupt)
        userResponse = window.prompt ("Sei sicuro di voler cambiare lo status dell'obiettivo?\n" +
        "Attenzione, essendo un obiettivo monitorato, continuando, si eliminerà anche il dato sul monitoraggio, " +
        "PERDENDO TUTTE LE ORE ACCUMULATE PER IL PRESENTE OBIETTIVO.\n\n\nScrivere 'CONTINUA' (senza apici e in maiuscolo), per continuare.") === "CONTINUA"
      
        userResponse = window.confirm("Sei sicuro di voler cambiare lo status dell'obiettivo?")

      if(userResponse){
        setStatus(token, id, {'status': goalStatus})
        .then( (resp) => {
          setGoMyHome(true)
        })
        .catch( err=>{
          console.log(err.response.data.message)
          alert("Attenzione. Si è verificato un problema.\n" + err.response.data.message.toString())
        })
      } else {
        alert('Non è stato impostato il corretto valore nel prompt o si è scelto di annullare. Non cambierà lo stato dell\'obiettivo.')
      }
    }

    if(goMyHome)
      return <Redirect to="/my" />

    return <Container>
        <Row className="justify-content-md-center">
          <Col md={12} xs={12}>
                <UserData userData={userData} />
                <Card style={{margin: 16}}>
                    <CardBody>
                        <CardTitle>
                          <h4 className="text-primary">{goal.name}</h4>
                          <h5 style={{paddingTop: 16}}>Lingua: {currentTargetLanguage ? currentTargetLanguage.description : "Da configurare"}</h5>
                          <Status status={status} conf={requiredConfiguration} date={ {createdAt, verifiedAt : verifiedAt, canceledAt: canceledAt, closedAt: closedAt} } />
                           {cefrLevel ?<h5>CEFR:  {cefrLevel} </h5>: null } 
                        </CardTitle>
                          {isInit ? <Configuration id={id} token={token} type={type} languages={languages}/> 
                          : <p className="text-secondary">{requiredConfiguration ? <br /> : <><i className="fa fa-eye-slash"></i>  Non monitorato da UNIME</>}</p>}
                        
                        <GoalInfo goal={goal} notitle={true} />
                        {enableReport ? <Report userGoal={userGoal}/> : null }
                        { isRun ? <RosettaStoneLink token={token} userGoal={userGoal} /> : ""}
                        <div className="text-center">
                        {canClose ? <Button color="success" size="lg" onClick={()=>setGoalStatus('closed')}><i className="fa fa-lock" ></i> Chiudi Goal</Button> : null}
                        {canVerify ? <div style={{paddingTop: 20}} ><Button color="success" size="lg" onClick={()=>setGoalStatus('verified')}><i className="fa fa-check"></i> Verifica Goal</Button></div> : null }
                        </div>
                    </CardBody>
                </Card> 
            </Col> 
        </Row>
    </Container>  
}