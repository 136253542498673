import React from 'react'
import {Col, Spinner} from 'reactstrap'
import GoalContent from './goalContent'
import {setGoal} from './apiActions'
//import moment from 'moment'
import {Redirect} from 'react-router-dom'

const messages = {
  confirm : "Attenzione, una volta selezionato l'obiettivo, non potrai modificarlo, autonomamente, per 15 giorni.",
  rusure : "Sicuro di procedere?",
  loading : "Caricamento dati. Attendere, prego.",
  noGoal : "Non risultano ancora obiettivi disponibili."
}

const errorMessages = {
  connection : "Errore di rete."
}

export default function List ({elenco, loading, token}){
    
    const [goId, setGoId] = React.useState(0)

    function SetUserGoal ({id, message}) {
  
        const c = window.confirm(`${messages.confirm}${message ? "\n\n" + message + "\n" : ""}\n${messages.rusure}`)
        if(c)
          setGoal(token,id)
          .then( (resp)=>{
            // redirect to home page
            console.log(resp.data)
            setGoId(resp.data.id)
          })
          .catch(err=>{
            console.log(err)
            alert(errorMessages.connection  + '\n' + err.message.toString())
          })
      }

    if(goId)
      return <Redirect to={"/users-goals/byId/"+goId} />

    if(loading)
      return <div className="text-center">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /> 
    <p className="lead">{messages.loading}</p>
      </div>

    return<> 
    { (elenco && elenco.length)
        ? 
        elenco.map( (goal) => {
            return <GoalContent key={goal.id} goal={goal} SetUserGoal={(id)=>SetUserGoal(id)} />
        }) 
        : 
        <Col><p className="lead text-danger text-center">
            <b>{messages.noGoal}</b>
        </p>
        </Col>
    } 
</>
}
