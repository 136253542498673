import React, {useState} from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import {config} from '../'
import DebugContent from '../debug'
//import LanguageSelector from '../languageSelector';
import StoreContext from '../core'
import validUserRole, {isAdmin} from '../../utils/validUserRole'

function UserAvatar(props) {
  // If a user avatar is available, return an img tag with the pic
  if (props.user.avatar) {
    return <img
            src={props.user.avatar} alt="user"
            className="rounded-circle align-self-center mr-2"
            style={{width: '32px'}}></img>;
  }

  // No avatar available, return a default icon
  return <><i
          className="fa fa-user-circle" style={{width: 24}}
         ></i><i
          className="fa fa-caret-down"
          style={{width: 16}}></i></>;
}

function AuthNavItem({user, isAuthenticated, authButtonMethod, toggle, role}) {

  if (isAuthenticated) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <UserAvatar user={user}/>
          
        </DropdownToggle>
        <DropdownMenu end>
        {config.app.map( (item,idx)=> {
          if(item.hidden && item.hidden.userMenu)
              return null
          
              return <DropdownItem key={"nav-key-"+idx} onClick={toggle} href={"/#" + item.path}>
              {item.menuLabel}
            </DropdownItem>
        
        })}

          <AdminMenu toggle={toggle} role={role} />
          <DropdownItem tag="a" href={config.auth.logout} onClick={()=> {authButtonMethod(); toggle()}}>Esci</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  // Not authenticated, return a sign in link
  return (
    <NavItem>
      <NavLink onClick={()=>{authButtonMethod(); toggle()}} href={config.auth.login}>Log In</NavLink>
    </NavItem>
  );
}

const AdminMenu = ({toggle, role}) => {
  
  return <>
    <DropdownItem divider />
    {config.appAdmin.map( (item,idx)=> {
          if(!(item.hidden && item.hidden.menu) && (isAdmin(role) || validUserRole(role,item.role)))
            return <DropdownItem key={"nav-key-"+idx} onClick={toggle} href={"/#" + item.path}>
                {item.menuLabel}
              </DropdownItem>
          return null    
          })
          
          }
    <DropdownItem divider />
  </> 
  
}

export default function NavBar (props) {

  const [isOpen, setIsOpen] = useState(false)
  const [modalOpen, setModalOpen ]= useState(false)
  const {isAuthenticated, user, role} = React.useContext(StoreContext) 

  const toggle = () =>  setIsOpen(!isOpen);

  function toggleModal() {
    setModalOpen(!modalOpen);
  }
    let menuLink = null;
    if (isAuthenticated) {
      menuLink = (<>
        {config.app.map( (item,idx)=> {
          if(item.hidden && item.hidden.menu)
              return null
          
          return <NavItem key={"nav-key-"+idx} onClick={toggle}>
            <RouterNavLink to={item.path} className="nav-link" exact>{item.menuLabel}</RouterNavLink>
        </NavItem> 
        
        })}
        </>
      );
    

    return (
      <>
        <Navbar color="dark" dark expand="md" fixed="top" >
            <NavbarBrand href={window.location.origin}>
              <span style={{paddingRight: 20}} 
              className="d-none d-md-inline">
                <img src={config.siteLogo} alt="unime logo"/>
              </span>
              <span className="d-block d-sm-none font-weight-bold" style={{letterSpacing: '1px', fontSize: "1.4em"}} >UniMe<i>Stone</i></span>
              </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="me-auto" navbar>
                <NavItem onClick={toggle}>
                  <RouterNavLink to="/" className="nav-link" exact >Home</RouterNavLink>
                </NavItem>
                {menuLink}
              </Nav>
              <Nav className="me-4" style={{paddingRight:34}} navbar>
                
                <AuthNavItem
                  toggle={toggle}
                  isAuthenticated={isAuthenticated}
                  authButtonMethod={props.authButtonMethod}
                  role={role}
                  user={user} />

              {config.helpButton && config.helpButton.enabled ? <NavItem>
                <NavLink tag="a" href={config.helpButton.url} target="_blank" style={{textDecoration: 'none', fontSize: '1.2em', color:"#fff"}}><i className="fa fa-info-circle"></i></NavLink>
              </NavItem> : ""}
              {config.debug? <NavItem>
                <NavLink onClick={()=>{toggleModal()}}>TOKEN</NavLink>
              </NavItem> : ""}
              </Nav>
              {
                /**
              <Nav className="flex-end" navbar style={{position: "absolute", right: 16}}>
                <NavItem>
                  <LanguageSelector />
                </NavItem>
              </Nav>
                 * 
                 */
              }
            </Collapse>
        </Navbar>
        <Modal isOpen={modalOpen} toggle={()=>toggleModal()}>
          <ModalBody>
            <DebugContent />
          </ModalBody>
        </Modal>
      </>
    );
  }
}