import React from 'react'
import {Route} from 'react-router-dom'
import config from '../../config'
import UserGoalsList from '../userGoalsList';
import MyGoal from '../my'
import UserGoal from '../goal'
import Filters from '../admin/filters'
import ReportGoals from '../admin/userGoals'
import ReportPlacements, {ReportUsers} from '../admin'
import User from '../admin/user'
import MyProfile from '../myProfile'
import validUserRole, {isAdmin} from '../../utils/validUserRole'
import Goals from '../admin/goals';
//const {auth} = config

/***
 * In ComponentRoutes bisogna aggiungere i componenti presenti in config.
 */
const ComponentRoutes = {
  'UserGoalsList' : UserGoalsList,
  'MyGoal' : MyGoal,
  'MyProfile' : MyProfile,
  'UserGoal' : UserGoal,
  'ReportPlacements': ReportPlacements,
  'ReportUsers' : ReportUsers,
  'Filters' : Filters,
  'User' : User,
  'ReportGoals' : ReportGoals,
  'Goals': Goals,
}

const ProtectedRoutes = (props) => {

    return config.app.map( (item,idx) => {
      if(item.hidden && item.hidden.route)
        return null 

      return <Route 
              path={item.path}
              key={idx}
              render={ () => React.createElement( ComponentRoutes[item.Component], {...item.attributes, ...props} ) } 
              />
    })

  }
  
  const AdminRoutes = ({token, role}) => {
  
    const {appAdmin} = config
    //console.log(role)
    return appAdmin.length ? appAdmin.map( (item,idx) => {

      const AdminNeeded = validUserRole(item.role, role )// > -1
  // abilito il menu admin in config.default
      if(config.debug || AdminNeeded || isAdmin(role) )
     
        return <Route 
                path={item.path}
                key={idx}
                render={ () => React.createElement( ComponentRoutes[item.Component], {...item.attributes, token : token} ) } 
                />
    return null  
    }) : null
    
  }
  
  const PublicRoutes = ({HomePage, isAuthenticated, user}) => <>
    {
      <Route exact path={"/"}
        render={() =>(<HomePage 
          isAuthenticated={isAuthenticated} 
          user={user} 
          />
              )
        } /> 
    }
  </>
  export default PublicRoutes
  export {ProtectedRoutes, AdminRoutes}