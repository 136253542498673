import React, {useEffect, useState} from 'react'
import ElencoUserGoals from './elencoUserGoals'
import config from '../../config'
import {Container, Row, Col, Alert, Spinner,Button } from 'reactstrap'
//import MessageContext from '../core'
import getCurrent, {getMyGoals} from './apiActions'
import {Link} from 'react-router-dom'
import SupportTeam from '../support'

const {app} = config;
const { pageTitle, pageSubTitle} = app.filter( (item) => (item.nameSpace === "my"))[0]

const defaultGoal = []

export function remap (data) {
    const t = []
    for ( let userGoal of data){
        try{
            const {status, goal, id, currentTargetLanguage} = userGoal
            const {name} = goal
            const {minTotHours} = userGoal
            t.push(
                {
                "#ID":id, 
                "status": status, 
                "obiettivo": name,
                "lingua": currentTargetLanguage ? currentTargetLanguage.description : "", 
                "ore":minTotHours,
            })
        }
        catch(err){
            console.log(err)
        }
    }
    return t
}


export default function MyGoal ({token, user}) {
    
    const [goals, setGoals] = useState([])
    const [loading, setLoading] = useState(true)
    const [current, setCurrent] = useState(true)

    useEffect(() => { 
      getMyGoals(token)
      .then( resp => {
        const data=  remap(resp.data)
        //console.log(data)
        setGoals(data)
      })
      .catch(err => {
        console.log(err)
        const data=  remap(defaultGoal)
        setGoals(data)
      })
      .then( ()=> {
        setLoading(false)
      })

      getCurrent(token)
      .then( resp => {
        setCurrent(resp.data.current)
      })
      .catch(error => {
          console.log(error)
          setCurrent(false)
      })
    }, [token])

   /* const {message} = React.useContext(MessageContext)
    if(!message.length)
        return <Spinner />
*/
    if(loading)
        return <div className="text-center">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /> 
            <p className="lead">Caricamento dati. Attendere, prego.</p>
        </div>

    const views = goals && goals.length 
    //const noCurrent = !goals.some( item => item.status === 'current' || item.status === 'init')

    // nascondo la lista se c'è un goal su status current
    const hideList = (views && !current) || !views

return <>
        <Container>
            <Row className="justify-content-md-center">
                <Col className="text-center">
                <h2 style={{padding: 24}}>{pageTitle}</h2>
                <small>{pageSubTitle}</small>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                { views
                ? <>
                    <ElencoUserGoals goals={goals}/>
                </>
                : <Col className="text-center">
                    <Alert color="info">
                    <p>Non risultano obiettivi attivi.</p>
                </Alert>
                </Col>}
            </Row>
            <Row className="justify-content-md-center">
                <Col>
                {hideList && <>
                    <Alert color="warning">
                <p className="text-center">
                    Gentile utente accedendo al servizio verrai reindirizzato 
                    al software <b>Rosetta Stone</b> la cui gestione è affidata a&nbsp; 
                    <b>Prolingua</b>. <br />
                    Utilizzando questo servizio autorizzi <b> Prolingua</b>  
                    &nbsp;a fornirti assistenza via mail.
                </p>
                </Alert>
                <div className="text-center">
                <Link to="/users-goals/list" ><Button size="lg" color="primary">Scegli un nuovo obiettivo da raggiungere.</Button></Link>
                </div>
                </> }
                <p style={{fontWeight: "10px"}}>
                <br />
                <pre className="text-muted">
                La società contitolare del trattamento:<br />
                PROLINGUA INTERNATIONAL Srl - 
                Sede legale in Roma via Angelo Ranucci 5 - CAP 00165 - Rea n. RM-1466038 / Partita IVA e Codice Fiscale 13680271007<br />
                </pre>

                </p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <SupportTeam token={token} user={user} />
            </Row>
        </Container>  
    </>
}

export {ElencoUserGoals};