import React from 'react';
import { Alert } from 'reactstrap';

export default function ErrorMessage ({message,debug}){
  if(!message)
    return null

  let Debug = null;
  if (debug) {
    Debug = <pre className="alert-pre border bg-light p-2"><code>{debug}</code></pre>
  }
  return (
     <Alert color="danger" className="text-center">
      <p className="mb-3"><i className="fa fa-exclamation-circle"></i> {message}</p>
      {Debug}
    </Alert> 
  );

}