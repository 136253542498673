import axios from 'axios'
import config from '../../config'

const {app} = config;
const {api} = app.filter( (item) => (item.nameSpace === "UsersPlacements"))[0]

export default function getPlacements(token) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getPlacements, axiosConfig)
}
