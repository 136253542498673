import React from 'react'
import {Link} from 'react-router-dom'
import { Button,
//     Input
    } from 'reactstrap'
//import {matchSorter} from 'match-sorter'
/*
function ordina_e_filtra(list, search) {
    console.log(search)
    let t = [] 
    
    for(let k of search){
        console.log(k,t)
        list.map( (item) => {
            
            if(item[ [k] ].indexOf(k) >-1){
                t.push(item)
                console.log(t)
            }
            return item
        } )
    }
  }
*/
export default function ElencoUserGoals ({goals}){

    const firstEl = Array.isArray(goals) ? goals[0] : null
    const fields = firstEl ? Object.keys(firstEl) : null
    const fieldsObj = {}
    for(let t of fields) {fieldsObj[t] = ""}
    //const [filtro, setFiltro] = React.useState(goals)
    //const [filterInput, setFilterInput] = React.useReducer((state, newState) => ({ ...state, ...newState }), fieldsObj)
/*
    function filtraDati () {
        const temp = []
        fields.map( item => {
            temp[item] = filterInput[item]
        })
        ordina_e_filtra(goals, temp)
    }

    function handleChange (field, value){
        setFilterInput({ [field]: value });
    }
*/
    return <div>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr><>
                            {fields.map( th => {
                            return <td key={th} >
                                {/**
                                 * <Input 
                                key={th} 
                                type="text" 
                                placeholder={th} 
                                name={th} 
                                value={filterInput[th] } 
                                onChange={ (e)=>{
                                    handleChange(th, e.target.value)
                                    //filtraDati()
                                } }/>
                                 * 
                                 */}
                                {th}
                                   
                                </td>
                        })}</>  
                        </tr>
                    </thead>
                    <tbody className="table-hover">
                        {goals.map( item => {
                            if(item["#ID"])
                                return <tr key={item["#ID"].toString() + Math.random()}>
                                    {fields.map( col => {
                                        if(!(typeof item[col] === "object" )){
                                            
                                            return <td key={col+Math.random()}>
                                                {col==="#ID" ? <div title={item["#ID"]}>
                                                <Link to={`/users-goals/byId/${item["#ID"]}`}>
                                                    <Button color="link"><i className="fa fa-eye"></i> dettagli </Button>
                                                </Link>
                                                </div>:item[col].toString()}
                                            </td>
                                        } 
                                        return <td key={col+Math.random()} className="text-center">[...]</td>
                                        })}
                                        
                                    </tr>
                            return null
                        })}
                    </tbody>
                </table>
            </div>
        </div>

}


/*
export default function ElencoGoals  ({goals}) {
    return goals.map( item => {
         if(typeof item === 'object'){
             return <Col md={4} xs={12} key={item.title + Math.random()}>
                 <Card style={{margin: 16}}>
                     <CardBody>
                         <CardTitle>
                             <h4 className="text-success">
                                 {item.title}
                             </h4>
                             <Alert color="info">
                                 <p className="text-secondary">Descrizione: <b>{item.description}</b><br />
                                 </p>
                                 <p className="text-secondary">
                                 <i className="fa fa-calendar-check"></i> Data: <b>{moment(item.completed).format("DD/MM/YYYY [alle] HH:mm")}</b>
                                 </p>
                             </Alert>
                         </CardTitle>    
                     </CardBody>
                     <CardFooter>
                         <Link to={`/users-goals/byid/${item.id}`}><Button color="primary">Dettagli</Button></Link>
                     </CardFooter>
                 </Card> 
             </Col> 
         }
             
         return <Alert color="danger">Errore nei dati.</Alert>
     })

 }
*/