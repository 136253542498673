import React from 'react'
import {Badge, Alert, Row, Col} from 'reactstrap'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import momentTimezone from 'moment-timezone'

function formatData (date){
    return moment(date)
    .tz('Europe/Rome')
    .format('DD/MM/YYYY HH:mm')
  }


const Status = ({status, date}) => {
    const {createdAt, closedAt, verifiedAt, canceledAt} = date
    console.log(date)
    let temp = ""
    let className = "text-primary"

    switch(status) {
        case 'init' : 
            temp = "Init - Da configurare" 
            className = "bg-warning"
            break;
        case 'run' :
            temp =  "In corso"
            className = "bg-primary"
            break;
        case 'verified' : 
            temp = "Verificato"
            className = "bg-success"
            break;
        case 'closed' : 
            temp = "Chiuso"
            className = "bg-muted"
            break;
        case 'canceled' : 
            temp = "Cancellato"
            className = "bg-danger"
            break;
        default: 
            className = "bg-danger"   
            temp = "NON RICONOSCIUTO"
    }
return <><Alert color="light" style={{borderBottom: "1px solid #bbb"}} >
    <Row className="justify-content-md-center align-items-center">
        <Col className="text-left">
        <span style={{fontSize: '1.5em'}}><Badge className={className} style={{padding: 10}} >STATUS: {temp}</Badge></span>
        </Col>
        <Col className="text-right">
        <div><b>Avviato il</b> {formatData(createdAt)}</div> 
        {verifiedAt ? <div>Verificato il {formatData(verifiedAt)}</div>: null } 
        {closedAt ? <div><b>Chiuso il </b>{formatData(closedAt)}</div>: null }
        {canceledAt ? <div><b>Annullato il </b>{formatData(canceledAt)}</div>: null }
        </Col>
         
    </Row>
    </Alert>
    
    </>
}
export default Status