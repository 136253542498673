import React, {useState, useContext} from 'react'
import {Input, FormGroup, Label, Button, Modal, ModalBody} from 'reactstrap'
import ExtraStudente, {ExtraDipendente} from './extraData'
import StoreContext from '../../core';
import config from '../../../config'
import axios from 'axios'
//import moment from 'moment';

export function setStatus(token, id, data) {
  const {app} = config;
  const {api} = app.filter( (item) => (item.nameSpace === "goal"))[0]

  const axiosConfig = {
      headers: ({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
       })
  }

  return axios.put(api.setStatus + id, data, axiosConfig)
}

export function setDate(token, id, date) {
  const {app} = config;
  const {api} = app.filter( (item) => (item.nameSpace === "goal"))[0]

  const axiosConfig = {
      headers: ({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
       })
  }

  return axios.put(api.setDate + id, {startedAt: date}, axiosConfig)
}

function SetGoalStatus({id, status, goalStatus}){
  const {token, isAdmin} = useContext(StoreContext) 
  const [changed, setChanged] = useState(null)

  if(changed) {
    return null
  }

  let color = "muted"
  let icon = ""

  switch (status){
    case 'Verifica': 
      color = "success"
      icon = "check"
      break;
    case 'Annulla':
      if(!isAdmin)
        return null;
      color = "danger"
      icon = "minus-circle"
      break;
    case 'Chiudi':
      if(!isAdmin)
        return null;
        color = "warning"
        icon = "lock"
    break;    
    default:
      color = "muted"
    
  }

  return <div>
    <Button color="link" onClick={ () => {
        let userResponse  = window.confirm(`Sei sicuro di voler cambiare lo status dell'obiettivo in ${goalStatus}?`)

        if(userResponse){
          setStatus(token, id, {'status': goalStatus})
          .then( (resp) => {
            setChanged(true);
            alert('Verifica effettuata.\nLa modifica sarà mostrata all\'utente al prossimo caricamento di pagina.\n')
          })
          .catch( err=>{
            console.log(err.response.data.message)
            alert("Attenzione. Si è verificato un problema.\n" + err.response.data.message.toString())
          })
        } else {
          alert('Operazione annullata.')
        }
      }}> <i className={`fa fa-${icon} text-${color}`} title={status +" Obiettivo"} /> </Button>
  </div>
}


function SetDate( {id, goalStatus, date}){
  const {isAdmin,token} = useContext(StoreContext)
  const [isOpen, setIsOpen] = useState(false)
  const [newDate, setNewData] = useState(date)
  const [changed, setChanged] = useState(null)
  const toggle = () => setIsOpen(!isOpen);

  
  if(!isAdmin || changed){
    return null
  }

  if(goalStatus !== "run"){
    return null
  }

  const onClick = () => {

    let userResponse  = window.confirm(`Sei sicuro di voler cambiare la data?`)

        if(userResponse){
          setDate(token, id, newDate )
          .then( (resp) => {
            setChanged(true);
            alert('Verifica effettuata.\nLa modifica sarà mostrata all\'utente al prossimo caricamento di pagina.\n')
          })
          .catch( err=>{
            console.log(err)
            try{
               alert("Attenzione. Si è verificato un problema.\n" + err.response.data.message.toString() )
            }
           catch (er){
            console.log(er)
           }  
          })
          .then( () => setIsOpen(false) )
        } else {
          alert('Operazione annullata.')
        }
  }

  const openModal = () => setIsOpen(true)
  const onChange = (val)=>setNewData(val)

  return <><Button color="link" onClick={()=>openModal()} title="modifica data"><i className="fa fa-pen text-danger"></i> edit
    </Button>
    
    <Modal isOpen={isOpen} toggle={toggle}>
    <ModalBody>
      <FormGroup>
        <Label>inserisci nuova data</Label>
        <Input type="text" value={newDate} onChange={(e)=>onChange(e.target.value)}></Input>
      </FormGroup>
      <br />
      <Button onClick={onClick}>invia</Button>
        
    </ModalBody>
  </Modal></>

}

 // This is a custom filter UI for selecting
  // a unique option from a list
export function SelectColumnFilter({column: { filterValue, setFilter, preFilteredRows, id }}) {
  
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <Input type="select"
      value={filterValue}
      onChange={e => {
        setFilter( e.target.value  || undefined)
      }}
    >
      <option value="">Tutti</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Input>
  )
}

const SelectStatus = ({column: { filterValue, setFilter }}) => {
  const options = ['run', 'canceled', 'init', 'verified','closed']

  return (
    <Input type="select"
      value={filterValue}
      onChange={e => {
        setFilter( e.target.value  || undefined)
      }}
    >
      <option value="">Tutti</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Input>
  )
}

export const hiddenColumns = [ 
  "firstName", "lastName", "codDip",
  "userData.cod_dip", "userData.firstName", "userData.lastName", "userData.matricola", "userData.email", 
  "userData.ssoId", "userData.cod_cds", "userData.des_cds", "userData.cod_ord", "userData.des_ord", 
  "userData.cod_pds", "userData.des_pds", "userData.des_dip", "userData.des_category",
  "userData.", 
]

const  altriDati = (data)  => {
  const {realm} = data.cell.row.original.userData

  try{
      if(realm === "unime.it") {
      return <ExtraDipendente data={data.cell.row.original.userData} />
    }
    return <ExtraStudente data={data.cell.row.original.userData} />
  }
  catch (err){
    console.log(err)
  }
}

//TODO: aggiungere refresh della table
//TODO: aggiungere ID copiabile.
const columns = [
    {
      Header: 'Utente',
      accessor: 'userData.ssoid',
      Filter: <></>,
      Cell: data => {
        const {firstName, lastName, email, ssoId, matricola} = data.cell.row.original.userData
        return  <>{firstName} {lastName} <span className="text-muted">[{matricola}]<br /> {email}<br /> <i>{ssoId}</i></span></>
      }
    },
    {
      Header: 'Cognome',
      accessor: 'userData.lastName',
    },
    {
      Header: 'Nome',
      accessor: 'userData.firstName',
    },
    {
      Header: 'Matricola',
      accessor: 'userData.matricola',
    },
    {
      Header: 'des_cds',
      accessor: 'userData.des_cds',
    },
    {
      Header: 'cod_cds',
      accessor: 'userData.cod_cds',
    },
    {
      Header: 'cod_dip',
      accessor: 'userData.cod_dip',
    },
    {
      Header: 'des_dip',
      accessor: 'userData.des_dip',
    },
    {
      Header: 'cod_ord',
      accessor: 'userData.cod_ord',
    },
    {
      Header: 'des_ord',
      accessor: 'userData.des_ord',
    },
    {
      Header: 'des_category',
      accessor: 'userData.des_category',
    },

    {
      Header: 'Email',
      accessor: 'userData.email',
    },
    {
      Header: 'Realm',
      accessor: 'userData.realm',
      Filter: <></>,
      filter: 'exact',
    },
    {
      Header: 'AltriDati',
      //accessor: 'realm', // TODO: realm sarà quello che deciderà cosa far vedere.
      Filter: <></>,
      Cell: data => altriDati(data)
      
    },
    {
      Header: 'Obiettivo',
      accessor: 'goal.name',
     // filter: 'exact',
     Filter: <></>,
    },
    {
      Header: 'Lingua',
      accessor: 'currentTargetLanguage.description',
     
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectStatus,
      filter: 'exact',
      Cell: (data) => {
        const {status}  = data.cell.row.original
        let color = ''
        switch (status) {
          case 'run':
            color += "primary"
            break;
          case 'canceled':
            color += "danger"
            break;
          case 'init':
            color += "warning"
            break;
          case 'verified':
            color += "success"
            break;
          case 'closed':
            color += "dark"
            break
          default: 
            color +="grey"
        }
        return <div className={'text-'+color +" text-center"} style={{padding: 5}}>
          <b>{status}</b>
          </div>
      }
    },
    {
      Header: 'Date',
      accessor: 'startedAt',
      Filter: <></>,
      Cell: (data) => {
        const {startedAt, status, id, verifiedBy = null, verifiedAt = null, closedAt = null, canceledAt = null, closedBy = null}  = data.cell.row.original
        return <>
          Avvio: <b>{startedAt}</b>
          {verifiedAt ? <><br/>Verificato: <b>{verifiedAt}</b> da {verifiedBy}</> : ""}
          {closedAt ? <><br/>Chiuso: <b>{closedAt} </b> {closedBy && <i>da {closedBy}</i>}</> : ""}
          {canceledAt ? <><br/>Cancellato: <b>{canceledAt} </b></> : ""}
          {status === "run" ? <SetDate goalStatus={status} id={id} date={startedAt} /> : ""}
        </>
      }
    },
    {
      Header: 'Azioni',
      accessor: 'id',
      Filter: <></>,
      Cell: (data) => {
        const [show, setShow] = useState(false)
       
        const {id, goal, userData, minTotHours, status} = data.cell.row.original

        return <div className="text-center">
        <Button color="link" href={"#/users-goals/byId/"+id} target="_blank"  rel="noopener noreferrer" >
          <i className="fa fa-eye"></i></Button><br />
            <Button color="link" target="_blank"  rel="noopener noreferrer"
            onClick={()=>{ navigator.clipboard.writeText(`UserGoalId: ${id}\nStatus: ${status}\nGoalId: ${goal.id}\nGoalName: ${goal.name}\nminTotHours: ${minTotHours}\nemail: ${userData.email}\nSsoID: ${userData.ssoId}\nUtente: ${userData.firstName} ${userData.lastName}\nMatricola: ${userData.matricola}\nCds Studente: ${userData.des_cds}`)
              .then( () => {
                setShow(true)
                setTimeout(() => {
                  setShow(false)
                }, 1000);
            })}}><i id={id} className="fa fa-clipboard"></i></Button>
            {show ? <p className="text-white bg-dark" style={{padding: 10, position: "absolute", bottom: "100", left: '50%'}}><b>COPIATO</b></p> : null}
            {status === 'run' || status === 'closed' ? <SetGoalStatus id={id} status={"Verifica"} goalStatus="verified" /> : ""} 
            {status === 'run' ? <SetGoalStatus id={id} status={"Chiudi"} goalStatus="closed" /> : ""}
            {status === 'closed' || status ==='init' ? <SetGoalStatus id={id} status={"Annulla"} goalStatus="canceled" /> : ""} 
        </div>
      }
    }
 
  ]

  export default columns
