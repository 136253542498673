import React, {useState} from 'react'
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy, useAsyncDebounce } from 'react-table'
import {filterGreaterThan, fuzzyTextFilterFn} from './filters'
import {Table, Pagination, PaginationItem, PaginationLink, 
  Input, InputGroup, InputGroupText,  
  Spinner, Badge, Row, Col, Button} from 'reactstrap'
import axios from 'axios'
import XLSX from 'xlsx'

function  esportaXls (token, url, globalFilter, filters, remap, setLoading) {
   
      
    setLoading(true)

    let filter = ""
  
    if(globalFilter) {
        filter='&q='+globalFilter
    }

    for (let item of filters) {
      filter += `&${item.id}_contains=${item.value}`
    }
    const newUrl = `${url}?_limit=-1${filter}` 
    
    const options =  {
      headers: ({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        })
    }
  
    axios.get(newUrl, options).then(
      (resp) => {
        setLoading(false)
        //let t = []
       
        remap(resp.data)
        .then(
          (data) =>{
          const wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(wb, ws, "foglio1")
          XLSX.writeFile(wb, "esportazione.xlsx");}
        )
        .catch( err =>{
          console.log(err)
            alert("Errore d'esportazione. Segnala ai tecnici il codice d'errore 789435.")
          })
      }
    )

    
    
  }
   
//import {tableColumns} from './config'
// import convertData from './helper'

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 700)

  return (
      <Input
        value={value || ""}
        color="outline"
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Cerca..."
      />

  )
}


function DefaultColumnFilter({column: { filterValue, setFilter, Header}}) {
  const [value, setValue] = useState(filterValue)
  const changeHandler = useAsyncDebounce(value => setFilter(value || undefined), 700)

     return (
      <Input
        value={value} 
        onChange={ e => {
          changeHandler(e.target.value)
          setValue(e.target.value)
        }
          
        
        }  
        placeholder={Header}
      />
    )
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

function FieldFilter({column}) {
  const {filterValue, setFilter} = column
  return (
    <Input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}


export default function DataTable(props) {
  const skipPageResetRef = React.useRef()
  const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter}),[])
  const [loading, setLoading] = useState(true)
  const [internalLoading, setInternalLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  //const [trashed, setTrashed] = useState(false)
  const trashed = false

  const [advancedSearch, setAS] = useState(false)
  const toggleAS = () => setAS(!advancedSearch)

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )


const {
  columns,
  url,
  data,
  fetchData,
  //lastModified,
  token,
  hiddenColumns,
  remapExport,
  pageCount: {records: totalRecords, pages: controlledPageCount}
} = props

  const {
    allColumns, 
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
   // rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    //preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
    // Get the state from the instance
    state: { 
      pageIndex, 
      pageSize,
      sortBy,
      groupBy,
      filters,
      globalFilter
    },
  } = useTable(
    {
      columns, data, initialState: { pageIndex: 0 , hiddenColumns: hiddenColumns}, 
      defaultColumn, filterTypes,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      manualPagination: true, 
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy:true,
      pageCount: controlledPageCount
    },
    useFilters, 
    useGlobalFilter, 
    useSortBy,
    usePagination,
  )

 // Debounce our onFetchData call for 1000ms
  const fetchDataDebounced = useAsyncDebounce(fetchData, 1000)

  React.useEffect(() => {
    console.log('calling')
    skipPageResetRef.current = false
     setInternalLoading(true)
    fetchDataDebounced({ pageIndex, pageSize, filters, globalFilter, groupBy, sortBy, token, trashed})
    .then( () => { setLoading(false); setInternalLoading(false)})
  }, [fetchData, pageIndex, filters, pageSize, globalFilter, groupBy, sortBy, trashed, token, fetchDataDebounced])


  const RowPagination = () => {
    return <Col>
        <p>In totale ci sono {totalRecords} righe -  Pagina{' '}
            <strong>
              {pageIndex + 1} di {pageOptions.length}
            </strong>
          </p>
        <Pagination>
          <PaginationItem>
            <PaginationLink first  onClick={() =>  { gotoPage(0)}} disabled={!canPreviousPage} />
          </PaginationItem>
          <PaginationItem>
          <PaginationLink previous onClick={() =>  { previousPage()}} disabled={!canPreviousPage} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink next onClick={() =>  { nextPage()}} disabled={!canNextPage}/>
          </PaginationItem>
          <PaginationItem>
          <PaginationLink last  onClick={() =>  {  gotoPage(pageCount - 1)}} disabled={!canNextPage} />
          </PaginationItem>
          <PaginationItem>
            <div style={{width: 10}}>
              &nbsp;
            </div>
          </PaginationItem>
        </Pagination>  
        <Pagination>
        <PaginationItem>
                <InputGroup>
               
                  <InputGroupText>
                    Record per pagina
                    </InputGroupText>
              
                  <div style={{width: 90}}>
                    <Input type="select"  
                            value={pageSize}
                            onChange={e => {
                              setInternalLoading(true); 
                              setPageSize(Number(e.target.value))
                            }}
                          >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))}
                          </Input>
                  </div>
                  </InputGroup>
                </PaginationItem>
                <PaginationItem>
            <div style={{width: 10}}>
              &nbsp;
            </div>
          </PaginationItem>
          <PaginationItem>
          <InputGroup>
                   
                    <InputGroupText>Vai alla pag:</InputGroupText>
                    <span style={{width: 60}}>
                      <Input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={e => {
                        setInternalLoading(true); 
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                      }}
                    />
                      </span>
                  </InputGroup>
                </PaginationItem>
        </Pagination>
        </Col>
  }

  //const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter}),[])
  if(loading)
    return <div className="text-center">
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /> 
          <p className="lead">Caricamento dati. Attendere, prego.</p>
      </div>

  const [columnCognome] = allColumns.filter( item => item['Header']==='Cognome')
  columnCognome.id= "lastName"
  const [columnNome] = allColumns.filter( item => item['Header']==='Nome')
  columnNome.id = "firstName"
  const [columnDip] = allColumns.filter( item => item['Header']==='cod_dip')
  columnDip.id = "codDip"
  
  return (
    <>     
      <Row>
       <RowPagination {...props}/>
      </Row>
      <Row style={{paddingBottom: 10}}>
      <Col md="8"> <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
      </Col>
      <Col className="text-right">
        <Button onClick={()=>esportaXls(token,url,globalFilter,filters, remapExport, setExporting)}> {exporting ? <i className="fas fa-spinner fa-spin"></i> : ""} Esporta XLS</Button>
      </Col>
      </Row>
       {// Pannello di ricerca avanzata.
     }
     <Row>
       <Col>
        <Button color="link" onClick={toggleAS}>Ricerca Avanzata <i className={`fa fa-caret-${advancedSearch ? "up": "down"}`}></i></Button>
       </Col>
     </Row>
      {!!advancedSearch &&<Row className={"justify-content-center"}>
       <Col>
        <div style={{paddingBottom: '1em'}}>
        <label> Cognome
            <FieldFilter column={columnCognome} />
        </label>&nbsp;
        <label> Nome
            <FieldFilter column={columnNome} />
        </label>
        <label> Codice Dipartimento
            <FieldFilter column={columnDip} />
        </label>
       </div>
       </Col>
      </Row>}
      <Row>               
        <Table {...getTableProps()} striped bordered className="table-responsive">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    { column.render('Header') }
                    <Badge {...column.getHeaderProps(
                      //column.getSortByToggleProps()
                      )} color="light" pill>
                    {/*column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon icon={faSortDown} />
                          : <FontAwesomeIcon icon={faSortUp} />
                    : <FontAwesomeIcon icon={faSort} /> */}
                    </Badge>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
            <tr scoped="row">
              <th
               colSpan={allColumns.length}
                style={{
                  textAlign: 'left',
                }}
              >
              </th>
            </tr>
          </thead>
          {
            internalLoading ? <thead><tr className="text-center"><td>
              <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} /> 
            </td>   
        </tr></thead> :
          <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell', { editable: false })}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>}
        </Table>
        <br />
        <RowPagination />
        <br />
        <br />
      </Row>
    </>
  )
}

