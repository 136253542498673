import axios from 'axios'
import config from '../../../config'

const {appAdmin} = config;
const {api} = appAdmin.filter( (item) => (item.nameSpace === "filters"))[0]

export default function getFilters(token) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getFilters, axiosConfig)
}

export function getFilter(token,id) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getFilter + id, axiosConfig)
}


export function setFilter(token,data) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.post(api.setFilter, data, axiosConfig)
}

export function removeFilter(token, element) {
    const tempElement = {...element, trash: true}

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.put(api.setFilter, tempElement, axiosConfig)
}

export function updateFilter(token, element) {
   

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.put(api.setFilter, element, axiosConfig)
}