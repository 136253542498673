import React from 'react'
import {Row, Col, Input, Alert, Button} from 'reactstrap'
import {saveData} from '../../utils'

const defaultUser = {
    "confirmed":true,
    "blocked":false,
    "_id":"5f92aeb0f644f60018d6eecf",
    "username":"MANUAL INSERT",
    "email":"MANUAL INSERT",
    "provider":"unime",
    "createdAt":"2020-10-23T10:21:36.685Z",
    "updatedAt":"2020-10-23T10:21:36.694Z",
    "__v":0,
    "role":{
        "_id":"5f906b1ff680ba009a6f4a24",
        "name":"Authenticated",
        "description":"Default role given to authenticated user.",
        "type":"authenticated","__v":0,
        "id":"5f906b1ff680ba009a6f4a24"
    },
    "id":"5f92aeb0f644f60018d6eecf"
}



export default function DebugContent (){
    const [token, setToken] = React.useState({})

    function saveIt(token, user = defaultUser){
        if(token !== ''){
           saveData(token, user)
           window.location.reload();
        } else {
            alert('Inserisci un token')
        }
    }

    return <Alert color="success">
        <Row>
            <Col>
                <Input onChange={(e)=>setToken(e.target.value)} placeholder="Token per login" />
            </Col>
            <Col>
                <Button color="primary" onClick={()=>{saveIt(token)}}>Registra Token</Button>
            </Col>
        </Row>
        <Row>
            <Col><p><a href="https://git.unime.it/rosettastone/unimestone-backend/wikis/home" rel="noopener noreferrer" target="_blank">Elenco Tokens per debug</a>
                </p>
            </Col>
        </Row>
    </Alert> 
}