const remapInputData = (values, restrictions) => {
    const infoArray = Object.keys(values.info)

    infoArray.push("choiceMessageCustom","dashboardMessageCustom")
    infoArray.map(element => {
        if(element === "peso")
           return values[element] = parseInt(values.info[element]) || 0

        return values[element] = values.info[element] 
    })

    restrictions.map(element => {
        if(!values.restrictTo[element])
            return values.restrictTo[element] = ""
        return Array.isArray(values.restrictTo[element]) ? values.restrictTo[element] = values.restrictTo[element].join(", ") : values.restrictTo[element]
    })
    
    values.minTotHours = parseInt(values.minTotHours)
    
    return values
}   

const remapData = (values, infoArray, restrictions) => {
    const output = {}
    if(values.id)
        output.id = values.id
    output.languages = values.languages
    output.monitoring = values.monitoring
    output.minTotHours = values.minTotHours 
    output.type = values.type
    output.info = {}
    output.restrictTo = {}
    infoArray.forEach(element => {
        output.info[element] = values[element]
    });
    output["name"] = values["name"]
    output.info['choiceMessageCustom'] = values['choiceMessageCustom']
    output.info['dashboardMessageCustom'] = values['dashboardMessageCustom']
    restrictions.forEach(element => {
        output.restrictTo[element] = values.restrictTo[element] //? values.restrictTo[element].split(";") : []
    })

    return output
}

export default remapData;
export {remapInputData}