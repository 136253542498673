import React from 'react'
import {Input} from 'reactstrap'
import {formatDate} from '../../../utils'
 // This is a custom filter UI for selecting
  // a unique option from a list
  export function SelectColumnFilter({column: { filterValue, setFilter, preFilteredRows, id }}) {
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
      <Input type="select"
        value={filterValue}
        onChange={e => {
          setFilter( e.target.value  || undefined)
        }}
      >
        <option value="">Tutti</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Input>
    )
  }

export const hiddenColumns = [ "lastName", "firstName", "matricola", "email", "cod_cds", "des_cds", "cod_ord", "des_ord", "cod_pds", "des_pds", "des_dip", "cod_dip", "des_category"]

const  altriDati = (data)  => {
  const {realm} = data.cell.row.original

  if(realm === "unime.it") {
    const {cod_role,des_role,des_profile,des_category,teacher} = data.cell.row.original

    return <div>
      Ruolo: [{cod_role}] {des_role}
      Profilo:  {des_profile} <br />
      Categoria: {des_category} <br />
      {teacher === 'S' ? "Docente" : "Non Docente"}<br />
  </div>
  }

  const { cod_cds, des_cds, cod_ord, des_ord, cod_pds, des_pds, des_dip, cod_dip} = data.cell.row.original
  return <div>
      <b>Dip:</b> [{cod_dip}] {des_dip}  <br />
      <b>Cds:</b> [{cod_cds}] {des_cds} <br />
      <b>Ord:</b> [{cod_ord}] {des_ord} <br />
      <b>Percorso:</b> [{cod_pds}] {des_pds}
  </div>

}



const columns =  [
  {
    Header: 'Utente',
    accessor: 'fullName',
  },
    {
      Header: 'Cognome',
      accessor: 'lastName',
    },
    {
      Header: 'Nome',
      accessor: 'firstName',
    },
    {
      Header: 'Matricola',
      accessor: 'matricola',
    },
    {
      Header: 'des_cds',
      accessor: 'des_cds',
    },
    {
      Header: 'cod_cds',
      accessor: 'cod_cds',
    },
    {
      Header: 'cod_dip',
      accessor: 'cod_dip',
    },
    {
      Header: 'des_dip',
      accessor: 'des_dip',
    },
    {
      Header: 'cod_ord',
      accessor: 'cod_ord',
    },
    {
      Header: 'des_ord',
      accessor: 'des_ord',
    },
    {
      Header: 'des_category',
      accessor: 'des_category',
    },
    {
      Header: 'email',
      accessor: 'email',
    },

    {
      Header: 'Realm',
      accessor: 'realm',
      Filter: SelectColumnFilter,
      filter: 'exact',
    },
    {
      Header: 'AltriDati',
      //accessor: 'realm', // TODO: realm sarà quello che deciderà cosa far vedere.
      Filter: <></>,
      Cell: data => altriDati(data)
      
    },
    {
      Header: 'Lingua',
      accessor: 'language',
      Filter: SelectColumnFilter,
      filter: 'exact',
    },
    {
      Header: 'CEFR',
      accessor: 'cefr',
      Filter: SelectColumnFilter,
      filter: 'exact',
    },
    {
      Header: 'Punteggio',
      accessor: 'scaledScore',
      Filter: <></>,

    },
    {
      Header: 'Data',
      accessor: 'completedAt',
      Filter: <></>,
      Cell: (data) => {
       return  formatDate(data.cell.row.original.completedAt)
      }
    },
    {
      Header: 'Tempo (s)',
      accessor: 'timeSpentSecs',
      Filter: <></>
    },
  ]

  export default columns