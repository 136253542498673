import React, {useState, useEffect} from 'react';

import GoalListContent from './goalListContent'
import {Row, Col, Input} from 'reactstrap'

const GoalsList = ({editGoal, languages, elenco, isAdmin}) => {
    const [filtered, setFiltered] = useState(elenco)
    const [search, setSearch] = useState("")

    const searchTitleCds = (searchTerm) => {
        setSearch(searchTerm)
        if(!elenco.length)
          return null;
  
        let filteredData = []
        let st = searchTerm.trim().toLowerCase()
        //console.log(elenco[1])
          if(st.length > 2){
            for (const item of elenco) {
                  const {name, info: {des_cds} } = item
                  const verificaCds = des_cds ? (des_cds.toLowerCase().indexOf(st) > -1): false
                  const verificaNome = name ? (name.toLowerCase().indexOf(st) > -1) : false
                  if (verificaNome || verificaCds){
                    filteredData.push(item)
                }            
            }
        } else {
          filteredData = elenco
        }
        setFiltered(filteredData)
    }
  
    useEffect(() => {
        setFiltered(elenco)
    }, [elenco]);

    return <Row>
        <Col md={12}>
        <label><i className="fa fa-search"></i> Filtra per Titolo / CDS 
            <Input type="text" value={search} onChange={(e)=>searchTitleCds(e.target.value)} />
        </label>

        </Col>
        { (filtered && filtered.length)
        ? 
            filtered.map( (goal) => {
                return <GoalListContent languagesList={languages} key={goal.id} goal={goal} isAdmin={isAdmin}
                editGoal={()=>{
                    editGoal(goal)}
                } />
            }) 
        : 
        search.length ? <Col><p>Nessun goal presente</p></Col> : null
        } 
    </Row>
}

export default GoalsList