
import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import {useFormikContext, useField} from 'formik'
import {Input} from 'reactstrap'

const CalcFromPesoField = (props) => {
  const [field] = useField(props);
  const {
    values: {peso},
    touched,
    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    if (peso && touched.peso) {
      setFieldValue(props.name, parseInt(peso * 25));
    }
  }, [peso, touched.peso, setFieldValue, props.name]);

  return <>
      <Input {...props} {...field} onChange={ (e) => { setFieldValue(props.name, e.target.value)}} />
    </>
};

const RestrictedFields = (props) => {
  const [field] = useField(props);
  const { values, setFieldValue } = useFormikContext();

  const {restrictTo}= values

  React.useEffect(() => {

    const {matricola, username, cod_role, cod_cds} = restrictTo
    
    if (matricola) {
      setFieldValue("restrictTo.username", "");
      setFieldValue("restrictTo.cod_role", "");
      setFieldValue("restrictTo.cod_cds", "");
    }

    if (username) {
      setFieldValue("restrictTo.matricola", "");
      setFieldValue("restrictTo.cod_role", "");
      setFieldValue("restrictTo.cod_cds", "");
    }
    if (cod_role) {
      setFieldValue("restrictTo.matricola", "");
      setFieldValue("restrictTo.username", "");
      setFieldValue("restrictTo.cod_cds", "");
    }
    if (cod_cds) {
      setFieldValue("restrictTo.matricola", "");
      setFieldValue("restrictTo.cod_role", "");
      setFieldValue("restrictTo.username", "");
    }
  }, [restrictTo, setFieldValue]);

  return (
    <>
      <Input {...props} {...field} onChange={ (e) => { setFieldValue(props.name, e.target.value)}} />
    </>
  );
};

const MarkDownEditor = (props) => {
    
    const {
        values,
        setFieldValue,
        } = useFormikContext();
     
        return (
      <>
        <MDEditor 
            value={values[props.name]}
            onChange={ (e)=> { setFieldValue(props.name, e)
            }}
            previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
        }}
        />
      </>
    );
}
export default MarkDownEditor;
export {CalcFromPesoField, RestrictedFields}